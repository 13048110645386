// types
import { FEATURE_FLAGS } from "../feature-flags.maps";
import type { RemoteConfigType } from "./types";

export const defaultConfig: RemoteConfigType = {
  [FEATURE_FLAGS.CORRECTIVE_ACTIONS]: true,
  [FEATURE_FLAGS.DOCUMENTS]: true,
  [FEATURE_FLAGS.FORMS]: true,
  [FEATURE_FLAGS.PEOPLE_MANAGEMENT]: true,
  [FEATURE_FLAGS.PUBLIC_REGISTRATION]: true,
  [FEATURE_FLAGS.TASKS]: true,
  [FEATURE_FLAGS.NOTIFICATIONS]: true,
  [FEATURE_FLAGS.DOCUMENTS_OFFLINE]: true,
  [FEATURE_FLAGS.FORMS_OFFLINE]: true,
  [FEATURE_FLAGS.VIDEO_MANAGEMENT]: true,
  [FEATURE_FLAGS.TRAINING_CERT]: true,

  // can be turned an
  [FEATURE_FLAGS.SITE_MANAGEMENT]: true,
  [FEATURE_FLAGS.TO_DO_LIST]: true,
  [FEATURE_FLAGS.DEV_ONLY]: true,
  [FEATURE_FLAGS.DASHBOARD]: true,
  [FEATURE_FLAGS.QUEUES]: true,
  [FEATURE_FLAGS.SCHEDULER]: true,

  [FEATURE_FLAGS.DIVISION_MANAGEMENT]: false,
  [FEATURE_FLAGS.CONTRACTOR_MANAGEMENT]: false,

  // unavailable
  [FEATURE_FLAGS.JHA]: false,
  [FEATURE_FLAGS.REPORTS]: false,
  [FEATURE_FLAGS.STANDARDS]: false,
  [FEATURE_FLAGS.SYSTEM_EXPORT]: false,
  dateFormat: "DD/MM/YYYY",
};

export const alwaysOnFeatures: RemoteConfigType = {
  [FEATURE_FLAGS.CORRECTIVE_ACTIONS]: true,
  [FEATURE_FLAGS.DOCUMENTS]: true,
  [FEATURE_FLAGS.FORMS]: true,
  [FEATURE_FLAGS.TRAINING_CERT]: true,
  [FEATURE_FLAGS.PEOPLE_MANAGEMENT]: true,
  [FEATURE_FLAGS.PUBLIC_REGISTRATION]: true,
  [FEATURE_FLAGS.TASKS]: true,
  [FEATURE_FLAGS.NOTIFICATIONS]: true,
  [FEATURE_FLAGS.DOCUMENTS_OFFLINE]: true,
  [FEATURE_FLAGS.FORMS_OFFLINE]: true,
  [FEATURE_FLAGS.VIDEO_MANAGEMENT]: true,

  // flags that not an be default
  [FEATURE_FLAGS.SITE_MANAGEMENT]: false,
  [FEATURE_FLAGS.DEV_ONLY]: false,
  [FEATURE_FLAGS.TO_DO_LIST]: false,
  [FEATURE_FLAGS.DASHBOARD]: false,
  [FEATURE_FLAGS.QUEUES]: false,
  [FEATURE_FLAGS.SCHEDULER]: false,

  [FEATURE_FLAGS.DIVISION_MANAGEMENT]: false,

  // unavailable flags
  [FEATURE_FLAGS.JHA]: false,
  [FEATURE_FLAGS.CONTRACTOR_MANAGEMENT]: false,
  [FEATURE_FLAGS.REPORTS]: false,
  [FEATURE_FLAGS.STANDARDS]: false,
  [FEATURE_FLAGS.SYSTEM_EXPORT]: false,
  dateFormat: "DD/MM/YYYY",
};
